import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import Grid from '../../elements/grid'
import { Content } from '../../elements/wyswyg'
import { breakpoint } from '../../shared/theme'
import SectionTransition from '../../elements/activeSectionDefault'

const ConfirmationWrapper = styled(SectionTransition)`
	padding: 48px 0 60px;

	@media ${breakpoint('tablet')} {
		padding: 132px 0 220px;
	}
`

const Title = styled.h2`
	text-align: center;
	margin-bottom: 30px;
	font-weight: 800;

	@media ${breakpoint('tablet')} {
		margin-bottom: 55px;
	}
`

const Description = styled(Content)`
	text-align: center;
	max-width: 1090px;
	margin: 0 auto;
	grid-column: -1 / 1;

	@media ${breakpoint('tablet')} {
		grid-column: 3 / span 10;
	}
`

const ContactConfirmation = ({ isActive, content }) => {
	const { confirmationHeading, copy } = content
	return (
		<ConfirmationWrapper isActive={isActive}>
			<InnerWrapper>
				<Title>{confirmationHeading}</Title>
				<Grid>
					<Description>
						<h3>
							<span dangerouslySetInnerHTML={{ __html: copy }}></span>
						</h3>
					</Description>
				</Grid>
			</InnerWrapper>
		</ConfirmationWrapper>
	)
}

ContactConfirmation.propTypes = {
	isActive: PropTypes.bool,
	content: PropTypes.object,
}

ContactConfirmation.defaultProps = {
	isActive: true,
	content: {},
}

export default ContactConfirmation
